:root {
  --large: 768px;
}

body {
  background-color: black;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  height: 100%;
}

@media only screen and (min-width: 769px) {
  body {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

/* -------- Header Aside -------- */

header {
  background: #fbfbfb;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease-out;
  width: 100%;
  height: 100%;
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94), ease background 500ms;
  transform: translate3d(-100vw, 0, 0);
  z-index: 99;
  overflow: auto;
}

@media (min-width: 769px) {
  header {
    transform: translate3d(-30vw, 0, 0);
    width: calc(30vw + 80px);
  }
}

.menu-open {
  transform: translate3d(0, 0, 0);
}

header.menu-open {
  background-color: #f2f2f2;
}

header.menu-open .header-container {
  opacity: 1;
}

.header-container {
  box-sizing: border-box;
  height: 100%;
  padding-left: 2.25em;
  padding-bottom: 2.25em;
  padding-right: 2.25em;
  padding-top: 4em;
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

@media only screen and (min-width: 769px) {
  .header-container {
    padding-right: 6em;
    padding-top: 6em;
  }
}

/* -------- Aside Content -------- */
#profile-wrap,
#title-wrap,
#bio-wrap {
  margin-bottom: 3.125em;
}

#profile-wrap img {
  width: 150px;
  height: 150px;
}

.image-mask {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
}

#title-wrap {
  text-transform: uppercase;
}

#title-wrap h1 {
  margin-bottom: 6px;
  line-height: 32px;
}

#title-wrap h3 {
  font-weight: 200;
}

#bio-wrap p {
  font-weight: 300;
  line-height: 1.5em;
}

#contact-wrap {
  padding-bottom: 3em;
}

/* @media only screen and (min-width: 769px) {
  #contact-wrap {
    bottom: 2.25em;
    position: absolute;
  }
} */

#contact-wrap a {
  color: #333;
  display: inline-block;
  margin-top: 6px;
  text-decoration: none;
}

#contact-wrap a:hover {
  text-decoration: underline;
}

/* -------- Floating Icon -------- */
#logo {
  position: fixed;
  left: 13px;
  top: 45vh;
  width: 40px;
  z-index: 999;
  font-size: 32px;
  font-weight: 700;
  display: none;
  color: #474747;
  opacity: 1;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

@media only screen and (min-width: 769px) {
  #logo {
    display: block;
  }
}

#menu-button.shift + #logo {
  opacity: 0;
}

/* -------- Hamburger Icon -------- */
#menu-button.shift {
  left: 2.25em;
}

#menu-button {
  display: block;
  position: fixed;
  top: 30px;
  left: 23px;
  z-index: 999;
  user-select: none;
  -webkit-user-select: none;
  transition: left ease 400ms;
}

#menu-button:hover span {
  opacity: 0.7;
}

#menu-button input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

#menu-button span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

@media only screen and (min-width: 769px) {
  #menu-button span {
    background: #474747;
  }
}

#menu-button span:first-child {
  transform-origin: 0% 0%;
}

#menu-button span:nth-last-child(1) {
  transform-origin: 0% 100%;
}

#menu-button input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #333;
}

#menu-button input:checked ~ span:nth-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menu-button input:checked ~ span:nth-child(4) {
  transform: rotate(-45deg) translate(0, -1px);
}

/* -------- Reel Content -------- */
.reel-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94), ease background 500ms;
}

.reel-hidden {
  transform: translate3d(30vw, 0, 0);
}

#image-fade {
  background: #171717;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: ease opacity 400ms;
  z-index: 99;
  pointer-events: none;
}

#image-fade.fade-active {
  opacity: 0.65;
}

.image-wrap {
  position: fixed;
  left: 0;
  top: 0px;
  right: auto;
  background-size: cover;
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 375px) {
  .image-wrap img {
    height: 100vh;
    transform: translate3d(-80vw, 0, 0);
    width: auto;
  }
}

/** -------- Image Captions -------- */

.image-caption {
  bottom: 40px;
  color: white;
  position: absolute;
  right: 10vw;
  text-align: right;
  z-index: 2;
}

.image-caption[data-index="7"] {
  color: #474747;
}

.image-caption p {
  margin: 6px 0 0 0;
}

@media only screen and (min-width: 769px) {
  .image-caption {
    right: 4vw;
  }
}

/* -------- Slider Progress -------- */

#image-index {
  color: white;
  position: absolute;
  text-align: right;
  z-index: 2;
  font-family: sans-serif;
  right: 10vw;
  bottom: 90px;
}

@media only screen and (min-width: 769px) {
  #image-index {
    left: 10vw;
    right: auto;
    bottom: 40px;
  }
}

#progressBar {
  width: 100%;
  margin: 10px auto;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.5);
}

#progressBar div {
  height: 100%;
  text-align: right;
  line-height: 22px; /* same as #progressBar height if we want text middle aligned */
  width: 0;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.02s ease;
}

#pagination {
  display: block;
  color: #00000050;
  font-size: 18px;
  margin: 10px auto;
  width: 100%;
  text-align: center;
  margin-bottom: -5px;
  cursor: pointer;
}

#pagination div {
  display: inline-block;
  transition: color 500ms linear;
}

#pagination div.active {
  color: #fff;
}

#pagination div:hover {
  color: #fff;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #464646;
  transition: opacity ease 0.5s;
  opacity: 0;
  position: absolute;
}
/* 
#portfolio1 {
  background-image: url('img/portfolio1.jpg');
} */

#portfolio1 {
  background-image: url('img/portfolio_1.jpg');
}

#portfolio2 {
  background-image: url('img/walden.jpg');
}

#portfolio3 {
  background-image: url('img/Rayleigh-Wine-Cellar.jpg');
}

#portfolio4 {
  background-image: url('img/portfolio_4.jpg');
}

#portfolio5 {
  
  background-image: url('img/portfolio_8.jpg');
}

#portfolio6 {
  background-image: url('img/portfolio_6.jpg');
}

#portfolio7 {
  background-image: url('img/Kent-Wine-Cellar.jpg');
}

#portfolio8 {
  background-image: url('img/Bucks-Wine-Cellar.jpg');
}

#portfolio9 {
  background-image: url('img/portfolio_2.jpg');
}

#portfolio10 {
  background-image: url('img/Rayleigh-Wine-Cellar-Wall.jpg');
}






